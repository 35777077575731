html[mode="ios"] ion-list {
    --padding-detail: 20px;
}
html[mode="md"] ion-list {
    --padding-detail: 16px;
}

.stoneSummary_background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
[data-is-setting-stone-found=true] .stoneSummary_background {
    background: rgba(0,0,0,0);
    background: linear-gradient(70deg,
        rgba(0,0,0,0) 0%,
        rgba(0,0,0,0) 40.9%,
        rgba(255,255,255,1) 41%,
        rgba(255,255,255,1) 49%,
        rgba(0,0,0,0) 49.1%,
        rgba(0,0,0,0) 57.9%,
        rgba(255,255,255,1) 58%,
        rgba(255,255,255,1) 66%,
        rgba(0,0,0,0) 66.1%,
        rgba(0,0,0,0) 73%,
        rgba(255,255,255,1) 73.1%,
        rgba(255,255,255,1) 76%,
        rgba(0,0,0,0) 76.1%,
        rgba(0,0,0,0) 82%,
        rgba(255,255,255,1) 82.1%,
        rgba(255,255,255,1) 83.9%,
        rgba(0,0,0,0) 84%,
        rgba(0,0,0,0) 100%);
    animation: setStoneFound 1000ms ease-out forwards;
}

@keyframes setStoneFound {
    0% {
        transform: scale(2) translateX(-35%);
    }
    100% {
        transform: scale(3) translateX(30%);
    }
}

.stoneSummary_headerLabel {
    z-index: 10;
}

.stoneSummary_headerLabel p {
    /* color: #FD5C85; */
    color: var(--ion-color-warning);
}

.stoneSummary_headerLabel .stoneSummary_locationHeader {
    color: var(--ion-color-primary-tint);
    font-weight: 600;
    white-space: normal;
}
[data-is-stone-found=true] .stoneSummary_locationHeader, [data-is-setting-stone-found=true] .stoneSummary_locationHeader {
    color: var(--ion-color-primary-contrast);
}

.stoneSummary_showMoreButton {
    transition: transform .4s ease-out, color .4s ease-out;
    color: var(--ion-color-primary-tint);
}
.stoneSummary_showMoreButton.stoneSummary_showMoreButton-detailShown {
    transform: rotate(135deg);
}
[data-is-stone-found=true] .stoneSummary_showMoreButton, [data-is-setting-stone-found=true] .stoneSummary_showMoreButton {
    color: var(--ion-color-primary-contrast);
}

.stoneDetail_wrapper {
    background-color: var(--ion-color-step-100);
    transition: max-height .4s ease-out;
    max-height: 0;
    overflow: hidden;
}
.stoneDetail_wrapper.stoneDetail_wrapper-detailShown {
    max-height: var(--stoneDetail-innerHeight);
}

.stoneDetail_inner {
    padding: var(--padding-detail);
    line-height: 1.25;
}

.stoneDetail_inner ion-grid {
    --ion-grid-padding: 0;
}

.stoneDetail_inner ion-col {
    --ion-grid-column-padding: 0;
    margin-bottom: .6rem;
    max-height: 80px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}
.stoneDetail_inner ion-col:first-of-type {
    padding-right: 5px;
}
.stoneDetail_inner ion-col:not(:first-of-type) {
    padding-left: 5px;
}
@media(min-width: 768px) {
    .stoneDetail_inner ion-col {
        max-height: 120px;
    }
}