#btr_app .firebaseui-container {
    background-color: var(--ion-card-background);
    color: var(--ion-text-color);
    font-family: var(--ion-font-family);
}

#btr_app .firebaseui-title, #btr_app .firebaseui-subtitle, #btr_app .firebaseui-label, #btr_app .firebaseui-input, #btr_app .firebaseui-input-invalid, #btr_app .firebaseui-text {
    color: var(--ion-text-color);
}

#btr_app .firebaseui-textfield.mdl-textfield .firebaseui-label:after, #btr_app .mdl-button--raised.mdl-button--colored {
    background-color: var(--ion-color-primary);
}

#btr_app .mdl-button--primary.mdl-button--primary {
    color: var(--ion-color-warning);
}

#btr_app .firebaseui-link {
    color: var(--ion-color-primary);
}

#btr_app .mdl-button, #btr_app .firebaseui-input {
    font-family: var(--ion-font-family);
}