ion-button.button-disabled {
    --opacity: 0;
}

.focusImageModal_wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.focusImageModal .ion-page ion-button {
    margin: 1rem 1.6rem;
}